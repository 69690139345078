* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: Montserrat, sans-serif;
  line-height: 1.65;
  font-weight: 500;
  font-size: 14px;
  scroll-behavior: smooth;
}
